import React from 'react';
import './Filter.scss';

function Filter(props) {

    function handleChange(event) {
        props.onChange(event.target.dataset.target);

        if(event.target.closest('.section').classList.contains('section__activities')) {
            document.querySelectorAll('.section__activities .filter-btn--active').forEach( (btn) => {
                btn.classList.remove('filter-btn--active');
            });
        } else {
            document.querySelectorAll('.section__schedule .filter-btn--active').forEach( (btn) => {
                btn.classList.remove('filter-btn--active');
            });
        }

        event.preventDefault();
        event.target.classList.toggle('filter-btn--active');
    }

    return (
        <div className={`filter-wrapper filter-wrapper--${props.color}`}>
            <h3 className="section__subheading">
                {props.heading}
            </h3>

            <div className="filter-btns">
                {props.filters.map((filter, index) => (
                    <a 
                        data-target={filter.target}
                        href={`#${filter.target}`}
                        className={`filter-btn ${index === 0 ? "filter-btn--active" : ''}`}
                        onClick={handleChange}
                    >
                        <span>{filter.label}</span>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default Filter;