import React, { useState } from 'react';
import './Schedule.scss';
import SlantedHeading from '../components/SlantedHeading.js';
import Filter from '../components/Filter.js';
import { ReactComponent as HeadingTop } from '../images/header-top.svg';
import { ReactComponent as HeadingBottom } from '../images/header-bottom.svg';
import { ReactComponent as SectionLeft } from '../images/section-left.svg';
import { ReactComponent as SectionRight } from '../images/section-right.svg';


const filters = [
    {
        'id': 0,
        'target': 'sthlm',
        'label': 'sthlm'
    },
    {
        'id': 1,
        'target': 'jkpg',
        'label': 'jkpg'
    },
    {
        'id': 2,
        'target': 'gbg',
        'label': 'gbg'
    },
    {
        'id': 3,
        'target': 'malmo',
        'label': 'malmö'
    }
];

const sthlm = [
    [
        {
            day: 'Fredag 12 Maj',
            items: [
                {
                    time: '10:25',
                    desc: 'Flyg avgår från ARN'
                },
                {
                    time: '13:50',
                    desc: 'Flyg framme i BCN'
                }
            ]
        },
        {
            day: 'Söndag 14 Maj',
            items: [
                {
                    time: '20:00',
                    desc: 'Flyg avgår från BCN'
                },
                {
                    time: '23:35',
                    desc: 'Flyg framme i ARN'
                }
            ]
        },
    ]
];

const jkpg = [
    [
        {
            day: 'Torsdag 11 Maj',
            items: [
                {
                    time: '17:00',
                    desc: 'Avresa med buss från Klubbhusgatan'
                },
                {
                    time: '20:30',
                    desc: 'Framme vid Clarion Hotel Kastrup airport'
                }
            ]
        },
        {
            day: 'Fredag 12 Maj',
            items: [
                {
                    time: '08:50',
                    desc: 'Flyg avgår från CPH'
                },
                {
                    time: '11:55',
                    desc: 'Flyg framme i BCN'
                }
            ]
        },
        {
            day: 'Söndag 14 Maj',
            items: [
                {
                    time: '16:10',
                    desc: 'Flyg avgår från BCN'
                },
                {
                    time: '19:10',
                    desc: 'Flyg framme i CPH'
                },
                {
                    time: '19:30',
                    desc: 'Bussresa till Klubbhusgatan'
                },
                {
                    time: '23:00',
                    desc: 'Framme i Klubbhusgatan'
                }
            ]
        },
    ]
];

const gbg = [
    [
        {
            day: 'Fredag 12 Maj',
            items: [
                {
                    time: '06:00',
                    desc: 'Flyg avgår från GOT'
                },
                {
                    time: '12:55',
                    desc: 'Flyg framme i BCN'
                }
            ]
        },
        {
            day: 'Söndag 14 Maj',
            items: [
                {
                    time: '17:05',
                    desc: 'Flyg avgår från BCN'
                },
                {
                    time: '23:40',
                    desc: 'Flyg framme i GOT'
                }
            ]
        },
    ]
];

const malmo = [
    [
        {
            day: 'Fredag 12 Maj',
            items: [
                {
                    time: '08:50',
                    desc: 'Flyg avgår från CPH'
                },
                {
                    time: '11:55',
                    desc: 'Flyg framme i BCN'
                }
            ]
        },
        {
            day: 'Söndag 14 Maj',
            items: [
                {
                    time: '16:10',
                    desc: 'Flyg avgår från BCN'
                },
                {
                    time: '19:10',
                    desc: 'Flyg framme i CPH'
                }
            ]
        },
    ]
];

function Schedule() {
    const [activeTab, setActiveTab] = useState('sthlm');

    function handleChange(newValue) {
        setActiveTab(newValue);
    }

    return (
        <div id="reseschema">  
            <div className="section section__schedule">
                <HeadingTop className="top-divider top-divider--blue" />
                <header className="header-wrapper">
                    <SlantedHeading color="text--blue" label="Reseschema" />
                </header>

                <Filter heading="Välj ditt kontor" filters={filters} color="blue" onChange={handleChange} />

                <HeadingBottom className="bottom-divider bottom-divider--blue" />
            </div>

            <div className="intiniary-wrapper">
                <SectionLeft className="bordered bordered--left bordered--blue" />
                <SectionRight className="bordered bordered--right bordered--blue" />
                <div className={`schedule__intiniary ${activeTab === 'sthlm' ? 'fade-in' : 'fade-out'}`}>
                    <div className="intiniary__inner">
                        {sthlm.map((items) => {
                            return (
                                <div>
                                    {items.map((subItems) => {
                                        return (
                                        <div className="intiniary__day">
                                            <h4>
                                                {subItems.day}
                                            </h4>

                                            {subItems.items.map((dayInfo) => {
                                                return (
                                                    <div className="intiniary__item">
                                                        <div className="item__time">
                                                            {dayInfo.time}
                                                        </div>

                                                        <div className="item__desc">
                                                            {dayInfo.desc}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={`schedule__intiniary ${activeTab === 'jkpg' ? 'fade-in' : 'fade-out'}`}>
                    <div className="intiniary__inner">
                        {jkpg.map((items) => {
                            return (
                                <div>
                                    {items.map((subItems) => {
                                        return (
                                        <div className="intiniary__day">
                                            <h4>
                                                {subItems.day}
                                            </h4>

                                            {subItems.items.map((dayInfo) => {
                                                return (
                                                    <div className="intiniary__item">
                                                        <div className="item__time">
                                                            {dayInfo.time}
                                                        </div>

                                                        <div className="item__desc">
                                                            {dayInfo.desc}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={`schedule__intiniary ${activeTab === 'gbg' ? 'fade-in' : 'fade-out'}`}>
                    <div className="intiniary__inner">
                        {gbg.map((items) => {
                            return (
                                <div>
                                    {items.map((subItems) => {
                                        return (
                                        <div className="intiniary__day">
                                            <h4>
                                                {subItems.day}
                                            </h4>

                                            {subItems.items.map((dayInfo) => {
                                                return (
                                                    <div className="intiniary__item">
                                                        <div className="item__time">
                                                            {dayInfo.time}
                                                        </div>

                                                        <div className="item__desc">
                                                            {dayInfo.desc}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={`schedule__intiniary ${activeTab === 'malmo' ? 'fade-in' : 'fade-out'}`}>
                    <div className="intiniary__inner">
                        {malmo.map((items) => {
                            return (
                                <div>
                                    {items.map((subItems) => {
                                        return (
                                        <div className="intiniary__day">
                                            <h4>
                                                {subItems.day}
                                            </h4>

                                            {subItems.items.map((dayInfo) => {
                                                return (
                                                    <div className="intiniary__item">
                                                        <div className="item__time">
                                                            {dayInfo.time}
                                                        </div>

                                                        <div className="item__desc">
                                                            {dayInfo.desc}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedule;