import './Information.scss';
import SlantedHeading from '../components/SlantedHeading.js';
import Collapsible from 'react-collapsible';
import { ReactComponent as Chevron } from '../images/barca-chevron.svg';
import { ReactComponent as HeadingTop } from '../images/header-top.svg';
import { ReactComponent as HeadingBottom } from '../images/header-bottom.svg';
import { ReactComponent as SectionLeft } from '../images/section-left.svg';
import { ReactComponent as SectionRight } from '../images/section-right.svg';

function Information() {

    return (
        <div id="information">  
            <div className="section section__info">
                <HeadingTop className="top-divider top-divider--yellow" />
                <header className="header-wrapper">
                    <SlantedHeading color="text--yellow" label="Information" />
                    <p>Övrig bra information och FAQ</p>
                </header>
                <HeadingBottom className="bottom-divider bottom-divider--yellow" />
            </div>

            <div className="faq-wrapper">
                <SectionLeft className="bordered bordered--left bordered--yellow" />
                <SectionRight className="bordered bordered--right bordered--yellow" />
                <div className="info__intiniary">
                    <div className="info__inner">
                        <div className="info__day">
                            <h4 className="text--yellow">Kontakt och frågor</h4>

                            <div className="info__item">
                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Vem är reseledare?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                            Anna Hörndahl håller i spakar och rattar: <a href="tel:+46736296660" style={{display: 'inline-block'}}>+46 (0)73 629 66 60</a>
                                        </p>
                                        <p>
                                            Carl S har koll på det mesta för A-gängets aktiviteter: <a href="tel:+46768894576" style={{display: 'inline-block'}}>+46 (0)76 889 45 76</a> 
                                        </p>
                                        <p>
                                            Men tänk på att du är vuxen och att mycket går att lösa med den här webben, en kompis eller Google. 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Vilket hotell bor jag på?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Radisson Blu 1882 Hotel, Barcelona Sagrada Familia 
                                        <br></br>
                                        Carrer Còrsega, 482, 08025 Barcelona Spanien 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Jag kan ingen spanska, vem hjälper mig i en nödsituation?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Ring Edde, por favor: <a href="tel:+46736797566">+46 736 797566</a>
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Jag har gått vilse/tappat bort gruppen</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                            Ring någon av dina kompisar. Här på webben finns programmet och du kan se var de troligen befinner sig. Försök att ta dig dit på egen hand så snart som går. 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">God morgon! Jag försov mig och är kvar på hotellet.</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Skicka ett sms till Anna (+46 736 296660) och berätta, försök att ansluta till gruppen så snart du kan. Heja heja, du löser det! 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Jag missade flyget!</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Vad tråkigt! Berätta gärna för Anna (+46 736 296660) så att ingen ringer Polisen. Det ordnar sig säkert, men tyvärr får egna plånboken ställa upp...
                                        </p>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>

                        <div className="info__day">
                            <h4 className="text--yellow">FAQ</h4>

                            <div className="info__item">
                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Tänk om jag blir sjuk på resan!</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Då gäller samma som hemma, vila, Netflix och krya på dig. Skicka ett sms till Anna (+46 736 296660) om du uteblir från någon aktivitet. 
                                        <br></br>Anslut när du är piggare eller så ses vi på hemvägen.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Måste man följa med på alla aktiviteter?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Inspirationsresan är frivillig. Men tänk på att allt är bokat och betalt, så det är inte särskilt schysst att dissa bara för att. Häng med!
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Hur noga är de egentligen med specialkost i Spanien?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Här är vi lite tveksamma. Känslan är: inte lika noga som i Sverige. Vi har anmält all specialkost till samtliga restauranger, som lovat att lösa efter bästa förmåga. Men ställ gärna en kontrollfråga när du får din mat. Och ha gärna med dig lite nödproviant om något skulle bli fel.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Vem är ansvarig om det händer en olycka? </span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Yellons olycksfallsförsäkring gäller under resan. Spara ev kvitton på vård, transporter och läkemedel.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Hur säger man "min kompis betalar" på spanska?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        El senor con la camisa y los lentes negros paga! 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Mellis och spontan-öl, vad gäller?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Yellon står för all förbokad mat och dryck. Eventuella utsvävningar står man själv för.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Finns det något dåligt väder?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Nej, det vet ju alla. Det verkar som att det blir regn, så: kläder efter väder. Vi kommer inte att äta utomhus om det regnar.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Vem har gjort denna grymma webb?</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                            Praktikant Joakim (jkpg) har tagit fram webbdesign, ChatGPT har utvecklat.
                                        </p>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>

                        <div className="info__day">
                            <h4 className="text--yellow">Söndagens fria tid - har du tips på vad jag kan göra?</h4>

                            <div className="info__item">
                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Strandpromenaden</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Strosa längs strandpromenaden och upplev det myllrande livet i hamnen och det gigantiska shoppingcentret Maremagnum.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">La Sagrada Familia</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Får du inte nog av La Sagrada Familia, passa på att ta en tur inomhus och upplev kyrkan i sin helhet.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Casa Batllo</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Casa Batllo är väl värt ett besök! Ett av Gaudis mästerverk och ett av Unescos världsarv.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Utsikt</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Vill du insupa utsikten en sista gång? Knata till takbaren på elfte våningen på hotell Barceló Raval eller Hotel 1898, båda på La Rambla. 
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Gatukonst och graffiti</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Gillar du gatukonst och graffiti rekommenderar vi att flanera i stadsdelen Raval och inspireras av väggar och murar.
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">La Rambla</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Flanera i långsam takt på la Rambla och stanna för en glass på Rocambolesc – gastronomisk glasskiosk i världsklass!
                                        </p>
                                    </div>
                                </Collapsible>

                                <Collapsible trigger={
                                    <div className="collapse__trigger">
                                        <span className="collapse__label">Sant Pau</span>
                                        <Chevron className="chevron--yellow" />
                                    </div>
                                }>
                                    <div className="collapse__content">
                                        <p>
                                        Upplev skatten Sant Pau, ett sjukhus från början av 1900-talet, en liten muromringad by i staden där paviljonger poppar upp som färgglada bakverk bland små trädgårdar
                                        </p>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Information;