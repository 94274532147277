import './Hero.scss';
import Button from '../components/Button.js';
import bgImage from '../images/hero-bg.png';
//import logoVideo from '../images/hero-animation-2.webm';
import logoVideo from '../images/animation web_1.gif';
import iconPlane from '../images/icon-plane.png';
import iconList from '../images/icon-list.png';
import iconInfo from '../images/icon-info.png';

const Hero = () => (
    <div className="hero" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="hero__inner">
            <div className="hero__logo">
                <img src={logoVideo} alt="" />
            </div>

            <div className="button-group">
                <Button label="Reseschema" href="#reseschema" icon={iconPlane} className="btn btn--primary btn--blue" />
                <Button label="Aktiviteter" href="#aktiviteter" icon={iconList} className="btn btn--primary btn--red" />
                <Button label="Information" href="#information" icon={iconInfo} className="btn btn--primary btn--yellow" />
            </div>
        </div>
    </div>
);

export default Hero;