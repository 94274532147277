import Hero from './partials/Hero.js';
import './App.scss';
import Schedule from './partials/Schedule.js';
import Activities from './partials/Activities.js';
import phoneImg from './images/iphone.png';
import Information from './partials/Information.js';

function App() {
  return (
    <div className="App">
      <div className="page-wrapper">
        <img src={phoneImg} alt="" />
        <div className="page-wrapper__inner">
          <div className="page__content">
            <Hero />
            <Schedule />
            <Activities />
            <Information />

            <div className="thanks">
              <p>Och ett stort tack Anna för all din planering och rodd som gjort detta möjligt! &lt;3</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
