import React from 'react';
import './Button.scss';

const Button = ({label, href, icon, className}) => {

    return (
        <a
            className={className}
            href={href}
        >
            <img
                src={icon}
                alt={label}
            />
    
            <span>
                {label}
            </span>
        </a>
    );
}

export default Button;